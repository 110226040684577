body{
  margin:0;
  padding:0;
  background-color: #fff;
}
.body {
    height: calc(100vh - 340px);

}
.spinnerStyle {
  width: 150px;
  height: 150px;
}
.apnadharm_info{
  color: #151478;
  font-size: 20px;
  text-align: justify;
  margin-bottom: 1rem;
}
.image_Reso{
width: 200px;
height: 250px;
margin-bottom: 5px;
}
.apnadharmLogoImage{
  width: 231px;
height: 70px;
object-fit: contain;
margin-top: 25px;
}
.deepLink{
  width: 200px;
  height: auto;
}
.logoCSS{
  color: #a199ee;
  font-weight: 600;
  letter-spacing: 0.01rem;
  font-size: 4rem;
}
.card{
  border-radius: 15px;
    background: #fff;
    /*box-shadow:  20px 20px 60px #bebebe,*/
    /*   -20px -20px 60px #ffffff;*/
}
.card-body{
    /* min-height: 300px; */
    border-radius: 15px;
    background: #fff;
    flex: 1 1 auto;
    padding: 0rem 1rem .8rem !important;
    /*box-shadow:  20px 20px 60px #bebebe,*/
    /*   -20px -20px 60px #ffffff;*/

}
.header{
    background-color: white;
}
/* .icon path{
    fill:"green";
     
} */
.icon-success {
  filter: invert(70%) sepia(66%) saturate(361%) hue-rotate(80deg) brightness(88%) contrast(93%);
}


.icon-warning{
  filter: invert(11%) sepia(97%) saturate(7207%) hue-rotate(0deg) brightness(100%) contrast(105%);
}
@media screen and (max-width: 600px) {
  .image_Reso{
    width: 124px;
    height: 163px;
    margin-bottom: 10px;
    margin-top: 10px;
    }
    .iconClass{
      width: 70px;
      height: 70px;
    }
    h3, .h3 {
      font-size: calc(1rem + 0.6vw);
  }
  .errorMargin{
    margin-top: -173px;
  }
  .errorVerification{
    margin-top: -35px;
  }
  .successVerification {
      margin-top: 48px;
  }
  .apnadharmLogoImage{
    width: 150px;
  height: 50px;
  margin-top: 10px;
  }
  .deepLink{
    width: 143px;
    height: auto;
  }
  .apnadharm_info{
    color: #151478;
    font-size: 15px;
    text-align: justify;
    margin-bottom: 1rem;
  }
  .spinnerStyle {
    margin-top: 2rem;
    width: 50px;
    height: 50px;
  }
}
